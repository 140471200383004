<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">结算单管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc operationControlWidth" style="align-items: flex-start;">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="结算单编号" class="searchboxItem  ci-full">
              <span class="itemLabel">结算单编号:</span>
              <el-input
                v-model="searchData.billNo"
                type="text"
                size="small"
                placeholder="请输入结算单编号"
                clearable
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="收款日期" class="searchboxItem ci-full">
              <span class="itemLabel">收款日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>
          <div class="searchbox" style="margin:10px 0 5px">
            <div title="是否开票" class="searchboxItem ci-full">
              <span class="itemLabel">是否开票:</span>
              <el-select
                size="small"
                v-model="searchData.invoice"
                clearable
                placeholder="请选择是否开票"
                style="width:100%"
              >
                <el-option value="true" label="是">是</el-option>
                <el-option value="false" label="否">否</el-option>
              </el-select>
            </div>

            <div title="排序方式" class="searchboxItem ci-full">
              <span class="itemLabel">结算单状态:</span>
              <el-select
                size="small"
                v-model="searchData.auditType"
                placeholder="请选择结算单状态"
                clearable
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="searchData.createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getExport()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="margin: 15px 0.675rem 5px">
          <span>合计付款金额：{{ payerAmountSum }} 元</span>
          <span style="margin:0 30px;">合计结算人数：{{ settlementUserCountSum }} 人</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column
                label="结算单编号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{scope.row.billNo}}{{scope.row.billSeq?'-'+scope.row.billSeq : ''}}
                </template>              
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="250px"
              />
              <el-table-column
                label="付款金额"
                align="right"
                show-overflow-tooltip
                prop="payerAmount"
                max-width="200px"
                min-width="200px"
              />
              <el-table-column
                label="结算人数"
                align="right"
                show-overflow-tooltip
                prop="settlementUserCount"
                max-width="100px"
              />
              <el-table-column
                label="收款日期"
                align="right"
                show-overflow-tooltip
                prop="payeeDate"
               max-width="150px"
                min-width="150px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.payeeDate | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="是否开票"
                align="left"
                show-overflow-tooltip
                prop="isInvoice"
                max-width="150"
              >
                <template slot-scope="scope">{{scope.row.isInvoice?"是":"否"}}</template>
              </el-table-column>

              <el-table-column
                label="修改理由"
                align="center"
                show-overflow-tooltip
                prop="remark"
                min-width="250px"
              />
              <el-table-column
                label="结算单状态"
                align="center"
                show-overflow-tooltip
                prop="audit_type"
                min-width="250px"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                    $setDictionary("FD_AUDIT_TYPE", scope.row.auditType)}}
                  </span>
                </template>
              </el-table-column>
               <el-table-column
                label="创建日期"
                align="right"
                show-overflow-tooltip
                prop="createTime"
                max-width="150px"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <div style="text-align:left;">
                    <el-button
                      type="text"
                      size="mini"
                      style="padding:0 5px"
                      @click="goWatch(scope.row.billId)"
                    >预览</el-button>
                    <el-button
                      type="text"
                      size="mini"
                      style="padding:0 5px"
                      :disabled="scope.row.auditType!='53'"
                      @click="billAudit(scope.row.billId, 'leader')"
                    >审批</el-button>
                  </div>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
     <el-dialog
      title="结算单"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 600px"></div>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
export default {
  name: "evaluate/statementManagementLeader",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      payerAmountSum: "",
      settlementUserCountSum: "",
      searchData: {
        auditType: "",
        compId: "",
        billNo: "",
        startTime: "",
        invoice: "",
        createTime:"",
      },
      areatreeList: [],
      CompanyList: [],
      statusList: [], //结算单状态list
      dialogCert: false,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getareatree();
    this.getStatusList();
  },
  methods: {
     /* 预览 */
    goWatch(billId) {
      this.$post('/biz/settlement/bill/billDetailsAndProjectDetail',{billId}).then(ret => {
        this.dialogCert = true;
        this.$nextTick(() => {
          pdf.embed(ret.data, "#pdf-cert");
        });
      }).catch(err => {
        return;
      })
    },
    getStatusList() {
      const statusList = this.$setDictionary("FD_AUDIT_TYPE", "list");
      const list = [];
      for (const key in statusList) {
        list.push({
          value: key,
          label: statusList[key]
        });
      }
      this.statusList = list;
      console.log(this.statusList);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.startTime) {
        params.payeeDateStart = this.searchData.startTime[0];
        params.payeeDateEnd = this.searchData.startTime[1];
      }
      if (this.searchData.invoice) {
        params.invoice = this.searchData.invoice;
      }

      if (this.searchData.auditType) {
        params.auditType = this.searchData.auditType;
      }
       if (this.searchData.createTime) {
        params.createDateStart = this.searchData.createTime[0];
        params.createDateEnd = this.searchData.createTime[1];
      }
      this.getCount(params);
      this.doFetch({
        url: "/biz/settlement/bill/leaderPage",
        params,
        pageNum
      });
    },
    getCount(params) {
      this.$post("/biz/settlement/bill/leaderPageStat", { ...params }).then(ret => {
        this.payerAmountSum = ret.data.payer_amount_sum;
        this.settlementUserCountSum = ret.data.settlement_user_count_sum;
      });
    },
    /* 导出 */
    getExport() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }
       if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.startTime) {
        params.payeeDateStart = this.searchData.startTime[0];
        params.payeeDateEnd = this.searchData.startTime[1];
      }
      if (this.searchData.invoice) {
        params.invoice = this.searchData.invoice;
      }

      if (this.searchData.auditType) {
        params.auditType = this.searchData.auditType;
      }
      if (this.searchData.createTime) {
        params.createDateStart = this.searchData.createTime[0];
        params.createDateEnd = this.searchData.createTime[1];
      }
      this.$post('/biz/settlement/bill/leaderExportPage',params).then(ret => {
        if (ret.status == "0") {
              window.open(ret.message);
            }

      }).catch(err => {
        return
      })
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1").then(ret => {
        this.areatreeList = [...ret.data];
      });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 审批 */
    billAudit(billId, formStu) {
      this.$router.push({
        path: "/web/evaluate/statementManagementFinanceToReview",
        query: {
          billId,
          formStu
        }
      });
      // this.$post('/biz/settlement/audit/submitModifyBillAudit')
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.searchData.billNo = ""),
            (this.searchData.compId = ""),
            (this.searchData.startTime = ""),
            (this.searchData.auditType = ""),
            (this.searchData.invoice = ""),
            (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scoped>
.searchbox > div .el-input {
  flex: 1;
}
.searchboxItem {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 6rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
